import logo from './logo.svg';
import './App.css';
import ImageUpload from "./components/imageCropper"
function App() {
  return (
    <div className="App">
      <h1>Image Cropper</h1>
      <h2>Crop your image below</h2>
      <ImageUpload />
    </div>
  );
}

export default App;
