import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

function ImageUpload() {
    const [image, setImage] = useState(null); // state for the uploaded image
    const [cropper, setCropper] = useState(null); // state for the cropper instance

    // function to handle the file input change
    const handleChange = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result); // set the image state to the base64 encoded string
            };
            reader.readAsDataURL(file);
        }
    };

    // function to handle the crop button click
    const handleCrop = () => {
        if (cropper) {
            // get the cropped image data
            const canvas = cropper.getCroppedCanvas();
            // convert it to a blob
            canvas.toBlob((blob) => {
                // create a new file object from the blob
                const file = new File([blob], "cropped-image.jpg", {
                    type: "image/jpeg",
                });
                // create a new URL for the file object
                const url = URL.createObjectURL(file);
                // create a temporary anchor element
                const link = document.createElement("a");
                // set the href and download attributes
                link.href = url;
                link.download = "cropped-image.jpg";
                // append the link to the document body
                document.body.appendChild(link);
                // click the link
                link.click();
                // remove the link from the document body
                document.body.removeChild(link);
            });
        }
    };

    return (
        <div className="image-upload">
            <h1>Image Upload and Crop</h1>
            <input class='hero-button' type="file" accept="image/*" onChange={handleChange} />
            {image && (
                <div className="cropper-container">
                    <Cropper
                        src={image}
                        style={{ height: 500, width: 500 }}
                        // Cropper.js options
                        aspectRatio={1}
                        guides={true}
                        cropBoxMovable={true}
                        cropBoxResizable={true}
                        viewMode={1}
                        // set the cropper state
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                    />
                    <button onClick={handleCrop}>Crop</button>
                </div>
            )}
        </div>
    );
}

export default ImageUpload;
